import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

let AdminRoute = ({component: Component, user, ...rest}) => {
    if (!user) {
        try {
            user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOGGED_IN_LOCALSTORAGE_KEY));
        }
        catch(exception) {
            localStorage.removeItem(process.env.REACT_APP_LOGGED_IN_LOCALSTORAGE_KEY);
        }
    }

    return (
        <Route
        {...rest}
        render={(props) => user && user.id != null
            ? <Component {...props} />
            : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
        />
    );
}

const mapStateToProps = state => {
    return { user: state.user };
};

AdminRoute = connect(mapStateToProps)(AdminRoute);

export default AdminRoute;