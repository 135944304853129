import { BrowserRouter, Switch, Route } from 'react-router-dom';
import React, {Suspense} from 'react';
import AdminRoute from './RouteHandler.js';
import ProgressBar from "react-topbar-progress-indicator";


const Login = React.lazy(() => import('../pages/Login'));
const Logout = React.lazy(() => import('../pages/Logout'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const PrintTestPDF =  React.lazy(() => import('../pages/page-component/PrintTestPDF'));

function Routes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<ProgressBar />}>
                <Switch>
                    <Route exact path="/login" name="Login" component={Login} />
                    <Route exact path="/logout" name="Login" component={Logout} />
                    <Route exact path="/print-test-pdf/:id" name="Test Print Page" component={PrintTestPDF} />
                    <AdminRoute path="/" component={Dashboard} />
                    <AdminRoute exact path="/dashboard" name="test2" component={Dashboard} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}

export default Routes