import { freeSet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { store } from 'react-notifications-component';

const notify = (message, type="success", title="", duration=2000) => {
    let icon;
    if (type === "success") {
        icon = freeSet.cilCheckCircle;
    }
    else if (type === "danger") {
        icon = freeSet.cilReportSlash;
    }
    else if (type === "warning") {
        icon = freeSet.cilWarning;
    }
    else if (type === "info") {
        icon = freeSet.cilInfo;
    }
    else {
        icon = freeSet.cilBell;
    }
    message = <>
        <div className="notification-custom-icon">
            <CIcon className="ico" width="30px" content={icon} />
        </div>
        <div className="notification-custom">
            <div className="notification-message">{message}</div>
        </div>
        </>;

    store.addNotification({
        title: title,
        message: message,
        type: type,
        container: "top-right",
        dismiss: {
            duration: duration,
            showIcon: true,
            onScreen: true,
            pauseOnHover: true
        }
    });
}

let notifyLoginExpiredOpen = false;
const notifyLoginExpired = () => {
    if (notifyLoginExpiredOpen) {
        return;
    }
    let message = <div className="">
                    <div className="display-3">Session is expired.<br />Redirecting in 5 seconds</div>
                </div>;

    store.addNotification({
        message: message,
        type: "warning",
        onRemoval: ()=>{notifyLoginExpiredOpen=false},
        container: "center",
        dismiss: {
            duration: 9999*1000,
            onScreen: false,
            click: false
        }
    });
    notifyLoginExpiredOpen = true;
}

export default notify;
export {notifyLoginExpired};