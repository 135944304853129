const InitialState = {
    'user' : null,
    'sidebarShow': 'responsive'
};

const appReducer = (state=InitialState, action) => {
    switch (action.type) {
        case 'auth/login':
            return {
                ...state,
                'user': action.payload
            }
        case 'auth/logout':
            return {
                ...state,
                'user' : null
            }
        case 'set':
            return {
            ...state,
            ...action
        }

        default: // No valid action performed hence state is preserved, so original state as it is
            return state
    }
}

export default appReducer;