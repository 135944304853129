import {Component, createRef, StrictMode} from 'react';
import Routes from './routes/Routes.js';
import axios from 'axios';
import AjaxLoader from './pages/element-components/AjaxLoader.js';
import notify, { notifyLoginExpired } from './pages/element-components/Notifications.js';
import {initiateLogOut} from './pages/Logout.js';

class App extends Component {
    constructor(props) {
        super(props);
        this.ajaxLoader = createRef();
        window.REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        this.state = {ajaxLoader: this.ajaxLoader};
        //////// interceptor to forward cookies and show loader ////////
        axios.interceptors.request.use((config) => {
            if (typeof config.withCredentials == "undefined") {
                config.withCredentials = true;
            }
            if (typeof config.showLoader == "undefined" || config.showLoader) {
                if (this.state.ajaxLoader.current) {
                    this.state.ajaxLoader.current.loadingStarted();
                }
            }
            return config;
        },
        (error) => {
            if (this.state.ajaxLoader.current) {
                this.state.ajaxLoader.current.loadingFinished();
            }
            return Promise.reject(error);
        });
        ////////////////////////////////////////////////////////////////
        ////// interceptor to finish loader and show login popup  //////
        axios.interceptors.response.use((resp) => {
            if (this.ajaxLoader.current) {
                this.ajaxLoader.current.loadingFinished();
            }
            if (resp.data && !resp.data.success) {
                if (resp.data.msg && window.showErrorNotification) {
                    notify(resp.data.msg, "danger");
                }
            }
            return resp;
        }, (error) => {
            if (this.ajaxLoader.current) {
                this.ajaxLoader.current.loadingFinished();
            }
            if (error.response && error.response.status === 401) {
                notifyLoginExpired();
                setTimeout(function() {
                    initiateLogOut(true);
                }, 5000);
            }
            if (error.response && error.response.status === 403) {
                notify("You are not authorized to do that.", "danger");
            }
            else {
                notify("Something went wrong.", "danger");
            }
            return Promise.reject(error);
        });
        ////////////////////////////////////////////////////////////////
        if (window.cache === undefined) {
            window.cache = {};
        }
        if (window.localStorage.preferences === undefined) {
            window.localStorage.preferences = '{}';
        }
    }

    render = () => {
        return (
            <StrictMode>
                <Routes/>
                <AjaxLoader ref={this.ajaxLoader} />
            </StrictMode>
        );
    }
}

export default App;